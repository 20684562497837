class SubscriptionApi {

  constructor(authToken) {
    this.authToken = authToken;
  }

  headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };

  BASE_URL = 'https://d2bazeiz5asygt.cloudfront.net/subscription';

  createHeaders() {
    return this.authToken ? {
      ...this.headers,
      'Authorization': 'Bearer ' + this.authToken
    } : this.headers;
  }

  async getSubscriptions() {
    return await fetch(`${this.BASE_URL}/subscriptions`, {
      method: 'GET',
      headers: this.createHeaders()
    });
  }

  async getPlans() {
    return await fetch(`${this.BASE_URL}/plans`, {
      method: 'GET',
      headers: this.createHeaders()
    });
  }

  async createSubscription(priceId) {
    return await fetch(`${this.BASE_URL}/create-subscription?priceId=${priceId}`, {
      method: 'GET',
      headers: this.createHeaders()
    });
  }

  async cancelSubscription(subscription) {
    return await fetch(`${this.BASE_URL}/cancel-subscription?subscriptionId=${subscription.subscriptionId}`, {
      method: 'GET',
      headers: this.createHeaders()
    });
  }
}

export default SubscriptionApi;