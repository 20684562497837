import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {Alert, Container} from 'reactstrap';


class SubscriptionStatus extends Component {

  state = {
    isLoading: true,
    errorMessage: '',
    subscription: null,
    priceList: []
  };

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    const response = await this.props.subscriptionApi.getPlans()
    if (!response.ok) {
      this.setState({
          errorMessage: `Failed to fetch plans: ${response.status} ${response.statusText}`,
          isLoading: false
        }
      )
    } else {
      const jsonData = await response.json();
      this.setState({
        subscriptionPlans: jsonData,
        isLoading: false
      });
    }
  };

  createSubscription = async (priceId) => {
    const response = await this.props.subscriptionApi.createSubscription(priceId)
    if (!response.ok) {
      this.setState({
          errorMessage: `Failed to create subscription: ${response.status} ${response.statusText}`,
          isLoading: false
        }
      )
    } else {
      await this.props.resetSubscription();
      const jsonData = await response.json();
      this.setState({
        subscription: jsonData,
        isLoading: false
      });
    }
  };

  render() {
    const {errorMessage, isLoading, subscriptionPlans, subscription} = this.state;
    const title = <h2>Choose a plan</h2>;

    if(isLoading) {
      return "Loading...";
    }

    if(subscription && subscription.clientSecret != null) {
      return <Redirect to={{
        pathname: '/paymentDetails',
        state: subscription
      }} />
    } else if (subscription && subscription.clientSecret == null) {
      // this is a free plan. Redirect to Subscription status
      return <Redirect to={{pathname: '/subscriptionStatus'}}/>
    }

    return (
      <div>
        {this.props.navbar}
        <Container style={{textAlign: 'left'}}>
          {title}
          {errorMessage ?
            <Alert color="warning">
              {errorMessage}
            </Alert> : null
          }

          {subscriptionPlans.map((plan) => (
            <div key={plan.planId}>
              <h3>{plan.planName}</h3>

              <p>
                ${plan.planPrice} / month
              </p>

              <button onClick={() => this.createSubscription(plan.planId)}>
                Select
              </button>
            </div>
          ))}
        </Container>
      </div>
    );
  }
}

export default withRouter(SubscriptionStatus);