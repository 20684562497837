import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Alert, Container} from 'reactstrap';


class SubscriptionCancel extends Component {

  state = {
    isLoading: false,
    errorMessage: '',
    subscriptionCancelSuccess: false,
    subscription: this.props.subscriptionStatus
  };

  cancelSubscription = async (subscription) => {
    const response = await this.props.subscriptionApi.cancelSubscription(subscription)
    if (!response.ok) {
      this.setState({
          errorMessage: `Failed to create subscription: ${response.status} ${response.statusText}`,
          isLoading: false
        }
      )
    } else {
      const jsonData = await response.json();
      this.setState({
        subscriptionCancelSuccess : true,
        subscription: jsonData,
        isLoading: false
      });
      await this.props.resetSubscription();
    }
  };

  render() {
    const {errorMessage, subscription, subscriptionCancelSuccess} = this.state;
    const title = <h2>Cancel Subscription</h2>;

    return (
      <div>
        {this.props.navbar}
        <Container style={{textAlign: 'left'}}>
          {title}
          {errorMessage ?
            <Alert color="warning">
              {errorMessage}
            </Alert> : null
          }

          {!subscriptionCancelSuccess?
            <div>
              <p>Are you sure you want to cancel subscription?</p>

              <button onClick={() => this.cancelSubscription(subscription)}>
                Cancel Subscription
              </button>
              <button onClick={() => this.props.history.push('/')}>
                Home
              </button>
            </div>
          :
            <div>
              <p>Your subscription has been canceled</p>
            </div>
          }
        </Container>
      </div>
    );
  }
}

export default withRouter(SubscriptionCancel);