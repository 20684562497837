import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Alert, Container} from 'reactstrap';

function RenderSubscriptionStatus(props) {
  if(props.subscription.status === "incomplete") {
    return (
      <div>
        <p>You have a subscription that is incomplete. Please complete setting up a subscription</p>

        <Link className="app-link" to={{
          pathname: "/paymentDetails",
          state: props.subscription
        }}>Complete Subscription</Link>
      </div>
    );
  } else if(props.subscription.status === "past_due") {
    return (
      <div>
        <p>You have a subscription that is past due. Please check your emails and method of payment to keep the subscription</p>

        <p>Alternatively, you can cancel the existing past due subscription and create a new one</p>

        <Link className="app-link" to={{
          pathname: "/cancelSubscription",
          state: props.subscription
        }}>Cancel Subscription</Link>
      </div>
    );
  } else if(props.subscription.status === "active") {
    return (
      <div>
        You have an active subscription

        <SubscriptionDetails subscription={props.subscription} />

        <p></p>
        <p></p>

        <p>You can cancel your subscription anytime. Just click on the link below and confirm</p>
        <Link className="app-link" to={{
          pathname: "/cancelSubscription",
          state: props.subscription
        }}>Cancel Subscription</Link>
      </div>
    );
  } else {
    return (
      <div>
        Unknown subscription status
      </div>
    )
  }
}


function SubscriptionDetails(props) {
  return (
    <div>
      <div>Plan name: {props.subscription.planName}</div>
      <div>Start Date: {props.subscription.startDate}</div>
    </div>
  );
};

class SubscriptionStatus extends Component {

  state = {
    isLoading: true,
    subscription: ''
  };

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    const response = await this.props.subscriptionApi.getSubscriptions()
    if (!response.ok) {
      this.setState({
          errorMessage: `Suscription status fetch failed: ${response.status} ${response.statusText}`,
          isLoading: false
        }
      )
    } else {
      const jsonData = await response.json();
      this.setState({
        isLoading: false,
        subscription: jsonData
      });
    }
  };

  render() {
    const {user} = this.props;
    const {errorMessage, isLoading, subscription} = this.state;
    const title = <h2>Account Details</h2>;

    return (
      <div>
        {this.props.navbar}
        <Container style={{textAlign: 'left'}}>
          {title}
          {errorMessage ?
            <Alert color="warning">
              {errorMessage}
            </Alert> : null
          }

          {isLoading? (
            <p>Loading...</p>
            ) : (
              <div>
                <p>Hello <b>{user.name}</b></p>
              {subscription ?
                <div>
                  <RenderSubscriptionStatus subscription={subscription} />
                </div>
              : <div>
                  <p>You are currently not subscribed. Please choose a plan to subscribe</p>
                  <Link className="app-link" to={"/subscriptionplans"}>Choose Plan</Link>
                </div>
              }
              </div>
          )}
        </Container>
      </div>
    );
  }
}

export default withRouter(SubscriptionStatus);