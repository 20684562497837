import React, {Component} from 'react';
import './App.css';
import Home from './Home';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {LoginCallback, SecureRoute, Security, withOktaAuth} from '@okta/okta-react';
import About from './About';
import PersonSearch from './PersonSearch';
import PersonDetails from './PersonDetails';
import PropertySearch from './PropertySearch';
import PropertyDetails from './PropertyDetails';
import SubscriptionStatus from './SubscriptionStatus';
import SubscriptionPlans from './SubscriptionPlans';
import SubscribeWithPaymentWithStripe from './SubscribeWithPayment';
import PersonApi from './api/PersonApi';
import PropertyApi from './api/PropertyApi';
import SubscriptionApi from './api/SubscriptionApi';
import TransactionsApi from "./api/TransactionsApi";
import NavBar from './NavBar';
import SubscriptionCancel from "./SubscriptionCancel";

const AuthWrapper = withOktaAuth(class WrappedRoutes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      authenticated: null,
      user: null,
      subscriptionStatus: null,
      personApi: new PersonApi(),
      propertyApi: new PropertyApi(),
      transactionsApi: new TransactionsApi(),
      subscriptionApi: new SubscriptionApi()
    };
    this.checkAuthentication = this.checkAuthentication.bind(this);
  }

  async checkAuthentication(forceAuth) {
    const authenticated = await this.props.authState.isAuthenticated;
    if (forceAuth || authenticated !== this.state.authenticated) {
      if (authenticated) {
        const user = await this.props.authService.getUser();
        let accessToken = await this.props.authService.getAccessToken();
        let subscriptionApi = new SubscriptionApi(accessToken);

        let response = await subscriptionApi.getSubscriptions();
        let subscriptionStatus = await response.json();

        this.setState({
          authenticated,
          user,
          subscriptionStatus: subscriptionStatus,
          personApi: new PersonApi(accessToken),
          propertyApi: new PropertyApi(accessToken),
          transactionsApi: new TransactionsApi(accessToken),
          subscriptionApi: new SubscriptionApi(accessToken)
        });
      } else {
        this.setState({
          authenticated,
          user: null,
          subscriptionStatus: null,
          personApi: new PersonApi(),
          propertyApi: new PropertyApi(),
          transactionsApi: new TransactionsApi(),
          subscriptionApi: new SubscriptionApi()
        });
      }
    }
  }

  async componentDidMount() {
    await this.checkAuthentication(false);
  }

  async componentDidUpdate() {
    await this.checkAuthentication(false);
  }

  async login() {
    if (this.state.authenticated === null) return; // do nothing if auth isn't loaded yet
    await this.props.authService.login('/');
  }

  async logout() {
    await this.props.authService.logout('/');
  }

  async resetSubscription() {
    this.setState({
      subscriptionStatus: null
    });
    await this.checkAuthentication(true);
  }

  render() {
    let {
      authenticated,
      user,
      subscriptionStatus,
      personApi,
      propertyApi,
      transactionsApi,
      subscriptionApi
    } = this.state;

    if (authenticated === null) {
      return null;
    }

    const navbar = <NavBar
      isAuthenticated={authenticated}
      user={user}
      login={this.login.bind(this)}
      logout={this.logout.bind(this)}
    />;

    return (
      <Router>
        <Switch>
          <Route
            path='/'
            exact={true}
            render={(props) => <Home {...props} authenticated={authenticated} user={user}
                                     subscriptionStatus={subscriptionStatus} personApi={personApi}
                                     propertyApi={propertyApi} transactionsApi={transactionsApi}
                                     subscriptionApi={subscriptionApi} navbar={navbar}/>}
          />
          <Route
            path='/about'
            exact={true}
            render={(props) => <About {...props} navbar={navbar}/>}
          />
          <Route
            path='/person'
            exact={true}
            render={(props) => <PersonSearch {...props} authenticated={authenticated} user={user}
                                             subscriptionStatus={subscriptionStatus} personApi={personApi}
                                             propertyApi={propertyApi} transactionsApi={transactionsApi}
                                             subscriptionApi={subscriptionApi}
                                             navbar={navbar}/>}
          />
          <SecureRoute
            path='/personProperty'
            exact={true}
            render={(props) => <PersonDetails {...props} authenticated={authenticated} user={user}
                                              subscriptionStatus={subscriptionStatus} personApi={personApi}
                                              propertyApi={propertyApi} transactionsApi={transactionsApi}
                                              subscriptionApi={subscriptionApi}
                                              navbar={navbar}/>}
          />
          <SecureRoute
            path='/transactions'
            exact={true}
            render={(props) => <PropertyDetails {...props} authenticated={authenticated} user={user}
                                                subscriptionStatus={subscriptionStatus}
                                                personApi={personApi} propertyApi={propertyApi}
                                                transactionsApi={transactionsApi} navbar={navbar}/>}
          />
          <Route
            path='/property'
            exact={true}
            render={(props) => <PropertySearch {...props} authenticated={authenticated} user={user}
                                               subscriptionStatus={subscriptionStatus}
                                               personApi={personApi} propertyApi={propertyApi}
                                               transactionsApi={transactionsApi}
                                               subscriptionApi={subscriptionApi} navbar={navbar}/>}
          />
          <SecureRoute
            path='/subscriptionStatus'
            exact={true}
            render={(props) => <SubscriptionStatus {...props} authenticated={authenticated} user={user}
                                                   subscriptionStatus={subscriptionStatus}
                                                   personApi={personApi}
                                                   propertyApi={propertyApi} transactionsApi={transactionsApi}
                                                   subscriptionApi={subscriptionApi}
                                                   navbar={navbar}/>}
          />
          <SecureRoute
            path='/subscriptionPlans'
            exact={true}
            render={(props) => <SubscriptionPlans {...props} authenticated={authenticated} user={user}
                                                  subscriptionStatus={subscriptionStatus}
                                                  personApi={personApi}
                                                  propertyApi={propertyApi} transactionsApi={transactionsApi}
                                                  subscriptionApi={subscriptionApi}
                                                  navbar={navbar}
                                                  resetSubscription={this.resetSubscription.bind(this)}/>}
          />
          <SecureRoute
            path='/paymentDetails'
            exact={true}
            render={(props) => <SubscribeWithPaymentWithStripe {...props}
                                                               authenticated={authenticated}
                                                               user={user} subscriptionStatus={subscriptionStatus}
                                                               personApi={personApi}
                                                               propertyApi={propertyApi}
                                                               transactionsApi={transactionsApi}
                                                               subscriptionApi={subscriptionApi}
                                                               navbar={navbar}
                                                               resetSubscription={this.resetSubscription.bind(this)}/>}
          />
          <SecureRoute
            path='/cancelSubscription'
            exact={true}
            render={(props) => <SubscriptionCancel {...props}
                                                   authenticated={authenticated}
                                                   user={user} subscriptionStatus={subscriptionStatus}
                                                   personApi={personApi}
                                                   propertyApi={propertyApi}
                                                   transactionsApi={transactionsApi}
                                                   subscriptionApi={subscriptionApi}
                                                   navbar={navbar}
                                                   resetSubscription={this.resetSubscription.bind(this)}/>}
          />
        </Switch>
      </Router>
    )
  }
});

class App extends Component {

  render() {
    return (
      <Router>
        <Security issuer='https://dev-64454836.okta.com/oauth2/default'
                  clientId='0oa2ganecrHnWQuNz5d7'
                  redirectUri={window.location.origin + '/callback'}
                  pkce={true}>
          <Route path='/callback' component={LoginCallback}/>
          <AuthWrapper/>
        </Security>
      </Router>
    )
  }
}

export default App;