import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {Alert, Container} from 'reactstrap';
import {CardElement, ElementsConsumer} from "@stripe/react-stripe-js";

class SubscribeWithPayment extends Component {

  state = {
    errorMessage: '',
    subscription: this.props.subscriptionStatus,
    stripe: this.props.stripe,
    elements: this.props.elements,
    name: 'Cardholder\'s name',
    paymentIntent: null
  };

  // When the subscribe-form is submitted we do a few things:
  //
  //   1. Tokenize the payment method
  //   2. Create the subscription
  //   3. Handle any next actions like 3D Secure that are required for SCA.
  handleSubmit = async (e) => {
    e.preventDefault();

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = this.state.elements.getElement(CardElement);

    // Use card Element to tokenize payment details
    let {error, paymentIntent} = await this.state.stripe.confirmCardPayment(this.state.subscription.clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: this.state.name,
        }
      }
    });

    if (error) {
      // show error and collect new card details.
      this.setState({
        errorMessage: error.message
      });
      return;
    } else {
      this.setState({
        paymentIntent: paymentIntent
      });
      await this.props.resetSubscription();
    }
  }

  render() {
    const {errorMessage, paymentIntent, name, stripe, elements} = this.state;
    const title = <h2>Subscribe</h2>;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return '';
    }

    if (paymentIntent && paymentIntent.status === 'succeeded') {
      return <Redirect to={{pathname: '/subscriptionStatus'}}/>
    }

    const cardElementOptions = {
      style: {
        base: {
          iconColor: '#c4f0ff',
          color: '#fff',
          fontWeight: '500',
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: '#fce883',
          },
          '::placeholder': {
            color: '#87BBFD',
          },
        },
        invalid: {
          iconColor: '#FFC7EE',
          color: '#FFC7EE',
        },
      }
    }

    return (
      <div>
        {this.props.navbar}
        <Container style={{textAlign: 'left'}}>
          {title}
          {errorMessage ?
            <Alert color="warning">
              {errorMessage}
            </Alert> : null
          }

          {process.env.REACT_APP_SOREAL_ENV === "dev" ?
            <div>
            <p>
              Try the successful test card: <span>4242424242424242</span>.
            </p>

            </div> : null
          }

          <hr/>

          <form onSubmit={this.handleSubmit}>
            <p>
            <label>
              Full name &nbsp; &nbsp;
              <input type="text" id="name" value={name} onChange={(e) => this.setState({name: e.target.value})}/>
            </label>
            </p>

            <p>
              <div>
                <CardElement options={cardElementOptions} />
              </div>
            </p>

            <div>
              <button>
                Subscribe
              </button>
            </div>
          </form>
        </Container>
      </div>
    );
  }
}

const SubscribeWithPaymentWithStripe = (props) => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <SubscribeWithPayment
        stripe={stripe}
        elements={elements}
        {...props}
      />
    )}
  </ElementsConsumer>
);

export default withRouter(SubscribeWithPaymentWithStripe);