import React, {Component} from 'react';
import './App.css';
import {Link, withRouter} from 'react-router-dom';
import {Button, Container} from 'reactstrap';

class Home extends Component {

  render() {
    return (
      <div className="app">
        {this.props.navbar}
        <Container fluid>
          <h3 className="home-page-main-title">Search real estate transactions across three bay area counties</h3>
          <p>
            <i>Santa Clara, Alameda and Marin counties supported. More counties to be added soon</i>
            <br/>
            <i>Transaction history going back to the 1970s</i>
          </p>
          <div className="d-flex flex-row flex-container flex-wrap justify-content-center">
            <div class="row">
              <div className="col-md-1">
                <Button color="secondary">
                  <Link className="app-link" to="/person">Search by name</Link>
                </Button>
              </div>

              <div className="col-md-1 offset-md-4">
                <Button color="secondary">
                  <Link className="app-link" to="/property">Search by Address</Link>
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default withRouter(Home);