import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Container} from 'reactstrap';

const Transaction = (transaction) => (
  <div className="transaction-container p-2 m-2 d-flex flex-column">
    <div className="transaction-body">
      <div className="subtitle-container">
        <div>Grantors: {transaction.grantor1NameFull},  {transaction.grantor2NameFull}</div>
        <div>Grantee: {transaction.grantee1NameFull},  {transaction.grantee2NameFull}</div>
        <div>Sale Amount: {transaction.transferAmount}</div>
        <div>Recording Date: {transaction.recordingDate}</div>
      </div>
    </div>
  </div>
);

class PropertyDetails extends Component {

  state = {
    propertyId: '',
    transactionList: ''
  };

  componentDidMount() {
    let search = new URLSearchParams(this.props.location.search);
    this.fetchData(search.get("propertyId"))
  }

  async fetchData(propertyId) {
    const response = await this.props.transactionsApi.findById(propertyId);
    if (!response.ok) {
      this.setState({
          errorMessage: `Search by name failed: ${response.status} ${response.statusText}`,
          isLoading: false
        }
      )
    } else {
      const jsonData = await response.json();
      this.setState({
        transactionList: jsonData
      });
    }
  };

  render() {
    const title = <h2>Property Details</h2>;

    return (
      <div>
        {this.props.navbar}
        <Container style={{textAlign: 'left'}}>
          {title}
          {this.state.transactionList ? (
            <div>
              <h3>Transactions</h3>
              {this.state.transactionList.length > 0 ? (
                <div>
                  {this.state.transactionList.map((transaction, index) => (
                    <Transaction {...transaction} />
                  ))}
                </div>
              ) : (
                <p>No transactions found</p>
              )}
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </Container>
      </div>
    );
  }
}

export default withRouter(PropertyDetails);