import React, {Component} from 'react';
import './App.css';
import {Container} from 'reactstrap';
import {Link} from "react-router-dom";

const MailTo = ({mailto, label}) => {
  return (
    <Link
      to='#'
      onClick={(e) => {
        window.location = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};

class About extends Component {

  render() {
    return (
      <div className="app">
        {this.props.navbar}
        <Container fluid>
          <h2>About Us</h2>

          <p>SoReal Inc is a Bay Area based fledgling startup created to add value to people interested in the Bay Area
            real estate</p>

          <p>To contact us, please email <MailTo label={"support@sorealinc.com"} mailto="mailto:support@sorealinc.com"/>
          </p>
        </Container>
      </div>
    );
  }
}

export default About;