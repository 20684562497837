import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Alert, Container} from 'reactstrap';


class PropertySearch extends Component {

  constructor() {
    super();
    this.state = JSON.parse(window.localStorage.getItem('propertySearch_State')) || {
      searchValue: '',
      errorMessage: null,
      isLoading: false,
      matchingAddresses: []
    };
  }

  setState(state) {
    window.localStorage.setItem('propertySearch_State', JSON.stringify(state));
    super.setState(state);
  }

  handleOnChange = event => {
    this.setState({
      searchValue: event.target.value,
      errorMessage: null,
      isLoading: false,
      matchingAddresses: []}
    );
  };

  async makeApiCall(searchInput) {
    const response = await this.props.propertyApi.searchByAddress(searchInput);
    if (!response.ok) {
      this.setState({
        searchValue: searchInput,
        errorMessage: `Search by address failed: ${response.status} ${response.statusText}`,
        isLoading: false,
        matchingAddresses: []
      })
    } else {
      const jsonData = await response.json();
      if(jsonData.length === 0) {
        this.setState({
          searchValue: searchInput,
          errorMessage: `No results found. Please revise your input`,
          isLoading: false,
          matchingAddresses: []
        })
      }
      this.setState({
        searchValue: searchInput,
        errorMessage: null,
        isLoading: false,
        matchingAddresses: jsonData
      });
    }
  };

  handleSearch = () => {
    this.setState({
      searchValue: this.state.searchValue,
      errorMessage: null,
      isLoading: true,
      matchingAddresses: []}
    );
    this.makeApiCall(this.state.searchValue);
  };

  handleClear = () => {
    this.setState({
      searchValue: '',
      errorMessage: null,
      isLoading: false,
      matchingAddresses: []}
    );
  };

  render() {
    const {errorMessage, isLoading, searchValue, matchingAddresses} = this.state;
    const title = <h2>Search by address</h2>;
    
    return (
      <div>
        {this.props.navbar}
        <Container style={{textAlign: 'left'}}>
          {title}
          <p><i>To get better search results, please use Ln for Lane, St for Street, Blvd for Boulevard etc.,</i></p>
          <p><i>You can also search with just the street name if it is unique enough: Ex: Sunset Dr</i></p>
          {errorMessage ?
            <Alert color="warning">
              {errorMessage}
            </Alert> : null
          }

          <input
            name="text"
            type="text"
            placeholder="Search"
            onChange={event => this.handleOnChange(event)}
            value={searchValue}
          />
          <p></p>
          <p>
            <button onClick={this.handleSearch}>Search</button>
            <button onClick={this.handleClear}>Clear</button>
          </p>

          {isLoading? <Alert>Loading...</Alert> : null}

          {matchingAddresses && matchingAddresses.length > 0 && !this.props.authenticated?
            <Alert>Please log in to view details</Alert>
            :
            <div>
              {matchingAddresses && matchingAddresses.length > 0 && (!this.props.subscriptionStatus || this.props.subscriptionStatus.status !== "active")?  <Alert>You need an <Link className="app-link" to={`/subscriptionStatus`}>active subscription</Link> to view details</Alert> : null}
            </div>
          }

          {matchingAddresses && matchingAddresses.map((address, index) => (
            <div>
              {this.props.authenticated && this.props.subscriptionStatus && this.props.subscriptionStatus.status === "active"?
                <div key={index}>
                  <Link className="app-link" to={`/transactions?propertyId=${address.propertyId}`}>{address.propertyAddressFull}, {address.propertyAddressCity}</Link>
                </div>
                :
                <div key={index}>
                  {address.propertyAddressFull}, {address.propertyAddressCity}
                </div>
              }
            </div>
          ))}
        </Container>
      </div>
    );
  }
}

export default withRouter(PropertySearch);