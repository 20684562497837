class PropertyApi {

  constructor(authToken) {
    this.authToken = authToken;
  }

  headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };

  BASE_URL = 'https://d2bazeiz5asygt.cloudfront.net/property';

  createHeaders() {
    return this.authToken ? {
      ...this.headers,
      'Authorization': 'Bearer ' + this.authToken
    } : this.headers;
  }

  async searchByAddress(address) {
    return await fetch(`${this.BASE_URL}/searchByAddress?address=${address}`, {
      method: 'GET',
      headers: this.createHeaders()
    });
  }

  async findById(id) {
    return await fetch(`${this.BASE_URL}/findById?propertyId=${id}`, {
      method: 'GET',
      headers: this.createHeaders()
    });
  }
}

export default PropertyApi;