import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Container} from 'reactstrap';


class PersonDetails extends Component {

  state = {
    personId: '',
    person: ''
  };

  componentDidMount() {
    let search = new URLSearchParams(this.props.location.search);
    this.fetchData(search.get("personId"))
  }

  async fetchData(personId) {
    const response = await this.props.personApi.findById(personId);
    if (!response.ok) {
      this.setState({
          errorMessage: `Search by name failed: ${response.status} ${response.statusText}`,
          isLoading: false
        }
      )
    } else {
      const jsonData = await response.json();
      this.setState({
        person: jsonData
      });
    }
  };

  render() {
    const title = <h2>Person Details</h2>;

    return (
      <div>
        {this.props.navbar}
        <Container style={{textAlign: 'left'}}>
          {title}
          {this.state.person ? (
            <div>
              We found the following properties associated with the name <b>{this.state.person.nameFull}</b>

              <p></p>
              <p><i>Note that these properties may belong to different individuals who share the same name</i></p>

              {this.state.person.properties ? (
                <ul>
                  {this.state.person.properties.map((address, index) => (
                    <div className="row" key={index}>
                      <Link className="app-link"
                            to={`/transactions?propertyId=${address.propertyId}`}>{address.propertyAddressFull}, {address.propertyAddressCity}</Link>
                    </div>
                  ))}
                </ul>
              ) : (
                <p>No properties found</p>
              )}
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </Container>
      </div>
    );
  }
}

export default withRouter(PersonDetails);