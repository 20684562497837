import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

const stripePromise = loadStripe('pk_test_51Js57mIC9fcGgd40ZCmKexFYKDvtluottxjJICuBx8YdVoghmoMmKMCp4u0OC2sY4fouLCTa6AFb5C3Ty8WaZfcc00xh6d3g2G');

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <App/>
    </Elements>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
