import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Alert, Container} from 'reactstrap';


class PersonSearch extends Component {

  constructor() {
    super();
    this.state = JSON.parse(window.localStorage.getItem('personSearch_State')) || {
      searchValue: '',
      errorMessage: null,
      isLoading: false,
      matchingNames: []
    };
  }

  setState(state) {
    window.localStorage.setItem('personSearch_State', JSON.stringify(state));
    super.setState(state);
  }

  handleOnChange = event => {
    this.setState({
      searchValue: event.target.value,
      errorMessage: null,
      isLoading: false,
      matchingNames: []}
    );
  };

  async makeApiCall(searchInput) {
    const response = await this.props.personApi.searchByName(searchInput);
    if (!response.ok) {
      this.setState({
        searchValue: searchInput,
        errorMessage: `Search by name failed: ${response.status} ${response.statusText}`,
        isLoading: false,
        matchingNames: []
      })
    } else {
      const jsonData = await response.json();
      if(jsonData.length === 0) {
        this.setState({
          searchValue: searchInput,
          errorMessage: `No results found. Please revise your input`,
          isLoading: false,
          matchingNames: []
        })
      }
      this.setState({
        searchValue: searchInput,
        errorMessage: null,
        isLoading: false,
        matchingNames: jsonData
      });
    }
  };

  handleSearch = () => {
    this.setState({
      searchValue: this.state.searchValue,
      errorMessage: null,
      isLoading: true,
      matchingNames: []}
    );
    this.makeApiCall(this.state.searchValue);
  };

  handleClear = () => {
    this.setState({
      searchValue: '',
      errorMessage: null,
      isLoading: false,
      matchingNames: []}
    );
  };

  render() {
    const {errorMessage, isLoading, searchValue, matchingNames} = this.state;
    const title = <h2>Search by name</h2>;

    return (
      <div>
        {this.props.navbar}
        <Container style={{textAlign: 'left'}}>
          {title}
          <p><i>Please enter the name of the person that you are searching for. If you don't get a match with full name, try entering either the first or last name</i></p>
          {errorMessage ?
            <Alert color="warning">
              {errorMessage}
            </Alert> : null
          }

          <input
            name="text"
            type="text"
            placeholder="Search"
            onChange={event => this.handleOnChange(event)}
            value={searchValue}
          />
          <p></p>
          <p>
            <button onClick={this.handleSearch}>Search</button>
            <button onClick={this.handleClear}>Clear</button>
          </p>

          {isLoading? <Alert>Loading...</Alert> : null}

          {matchingNames && matchingNames.length > 0 && !this.props.authenticated?
            <Alert>Please log in to view details</Alert>
            :
            <div>
            {matchingNames && matchingNames.length > 0 && (!this.props.subscriptionStatus || this.props.subscriptionStatus.status !== "active")? <Alert>You need an <Link className="app-link" to={`/subscriptionStatus`}>active subscription</Link> to view details</Alert> : null}
            </div>
            }

          {matchingNames && matchingNames.map((name, index) => (
            <div>
              {this.props.authenticated && this.props.subscriptionStatus && this.props.subscriptionStatus.status === "active"?
                <div key={index}>
                  <Link className="app-link" to={`/personProperty?personId=${name.personId}`}>{name.nameFull}</Link>
                </div>
                :
                <div key={index}>
                  {name.nameFull}
                </div>
              }
            </div>
          ))}
        </Container>
      </div>
    );
  }
}

export default withRouter(PersonSearch);